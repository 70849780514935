import React, { useState } from "react";
import { services } from "../services";

export default function UpdateUserCourseCompletionPage() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      console.log("Selected file:", file);
    }
  };

  const handleSubmit = async () => {
    setIsUploading(true);
    if (selectedFile) {
      console.log(
        "File details:",
        selectedFile.name,
        selectedFile.size,
        selectedFile.type
      );

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("secret", "GTNQMY05pl");

      try {
        const response = await services.requestSvc.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("File uploaded successfully:", response.data);
        setUploadStatus("File uploaded successfully!");
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadStatus("Error uploading file. Please try again.");
      } finally {
        setIsUploading(false);
      }
    } else {
      setUploadStatus("No file selected");
    }
  };

  return (
    <div className="background-img w-screen h-screen centered-page">
      <div className="flex flex-col gap-y-3 p-8 rounded-xl bg-white">
        <h1 className="text-3xl font-bold mb-10 text-center">
          Upload CSV File
        </h1>
        <input
          type="file"
          className="border border-gray-300 p-2 rounded w-full mb-4"
          accept=".csv, .xlsx"
          onChange={handleFileChange}
        />
        <button
          onClick={handleSubmit}
          className="btn-primary w-full"
          disabled={isUploading}
        >
          {isUploading ? "Uploading..." : "Submit"}
        </button>
        {uploadStatus && (
          <p
            className={`text-${
              uploadStatus.includes("Error") ? "red" : "green"
            }-500`}
          >
            {uploadStatus}
          </p>
        )}
      </div>
    </div>
  );
}

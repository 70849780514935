import React, { useCallback, useEffect, useMemo } from "react";
import usePromise from "../utils/hooks/usePromise";
import { services } from "../services";
import {
  District,
  Gender,
  OnboardingStatus,
  State,
} from "@unicef/shared-resources/models/db";
import Loader from "../assets/animated-images/loader";
import IndiaMap from "./CountryStats/IndiaMap";
import { Link, useNavigate } from "react-router-dom";
import _, { isUndefined } from "lodash";
import Stats from "./CountryStats/Stats";
import { objectToQueryString, queryStringToObject } from "../utils/query";
import z from "zod";
import { useAuth } from "../utils/hooks/useAuth";

export type CountryStats = {
  year: string;
  month: string;
  onboardingStatus: OnboardingStatus;
  gender: Gender;
  onboardingCount: number;
  courseCompletionCount: number;
};

export type StateOverviewStat = {
  stateId: number;
  stateName: string;
  stateLimit: number;
  onboardingCount: number;
  courseCompletionCount: number;
};

export type DateParam = {
  month: number;
  year: number;
};

export const StatParamsSchema = z.object({
  stateId: z.coerce.number().optional(),
  districtId: z.coerce.number().optional(),
  dateRange: z
    .object({
      from: z
        .object({
          month: z.coerce.number(),
          year: z.coerce.number(),
        })
        .optional(),
      to: z
        .object({
          month: z.coerce.number(),
          year: z.coerce.number(),
        })
        .optional(),
    })
    .optional(),
});

export type StatsParams = z.infer<typeof StatParamsSchema>;

export type StatsParamsSetter = <F extends keyof StatsParams>(
  field: F
) => (value: StatsParams[F]) => void;

export default function CountryStatsPage() {
  const navigate = useNavigate();
  const { admin } = useAuth();

  /*




  Parsing and setting stats parameters




  */
  const statParams = useMemo<StatsParams>(() => {
    const currentState = queryStringToObject(window.location.search.slice(1));

    return StatParamsSchema.parse(
      _.merge(
        {
          dateRange: {
            from: {
              month: 5,
              year: 2024,
            },
            to: {
              month: new Date().getMonth() + 1,
              year: new Date().getFullYear(),
            },
          },
        },
        currentState
      )
    );
  }, [window.location.search]);

  /*




  Fetching states and districts data




  */
  const {
    result: states,
    error: fetchStatesErr,
    loading: loadingStates,
  } = usePromise(
    {
      fn: async () => services.requestSvc.get<State[]>("/states"),
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [admin?.stateId]
  );

  const {
    result: districts,
    error: fetchDistrictsErr,
    loading: loadingDistricts,
  } = usePromise(
    {
      fn: async () => {
        if (statParams.stateId) {
          return services.requestSvc.get<District[]>(
            "/districts-by-state-id?stateId=" + Number(statParams.stateId)
          );
        } else return [];
      },
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [statParams.stateId]
  );

  /*




  Fetching stats




  */
  const {
    result: stateOverviewStats,
    error: fetchStateOverviewStatsError,
    loading: loadingStateOverviewStats,
  } = usePromise(
    {
      fn: async () =>
        services.requestSvc
          .get<StateOverviewStat[]>("/get-states-overview-stats")
          .then((result) => {
            return {
              totalOnboardingCount: result.reduce(
                (sum, cur) => sum + (cur.onboardingCount || 0),
                0
              ),
              totalCourseCompletionCount: result.reduce(
                (sum, cur) => sum + (cur.courseCompletionCount || 0),
                0
              ),
              result,
            };
          }),
      runOnMount: { args: [] },
    },
    []
  );

  const {
    result: countryStats,
    error: fetchCountryStatsErr,
    loading: loadingCountryStats,
  } = usePromise(
    {
      fn: async () => {
        const queryString = new URLSearchParams(
          _.omitBy(statParams, isUndefined) as unknown as undefined
        ).toString();

        const result = await services.requestSvc.get<CountryStats[]>(
          "/get-stats-by-states?" + queryString
        );

        const dateRangeLow = statParams.dateRange?.from;
        const dateRangeHigh = statParams.dateRange?.to;

        const filteredResult = result.filter((r) => {
          const fallsBehind =
            dateRangeLow &&
            dateRangeLow.year * 100 + dateRangeLow.month >
              Number(r.year) * 100 + Number(r.month);
          const fallsAhead =
            dateRangeHigh &&
            dateRangeHigh.year * 100 + dateRangeHigh.month <
              Number(r.year) * 100 + Number(r.month);
          return !fallsBehind && !fallsAhead;
        });

        return filteredResult;
      },
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [statParams, states, districts]
  );

  /*



  Misc



  */

  const setStatsParams = useCallback<StatsParamsSetter>(
    (field) => (value) => {
      const newStatParams = _.omitBy(
        {
          ...statParams,
          [field]: value || undefined,
        },
        isUndefined
      ) as StatsParams;

      const district = districts?.find(
        (d) => Number(d.id) === statParams.districtId
      );
      if (district && Number(district.stateId) !== newStatParams.stateId) {
        newStatParams.districtId = undefined;
      }

      console.log("New stat params", newStatParams);

      navigate({ search: objectToQueryString(newStatParams) });
    },
    [navigate, statParams, districts]
  );

  return (
    <div className="h-screen flex flex-col">
      <div className="py-2 px-4 bg-white text-left border-b border-gray-400">
        <Link to="/">
          <button className="btn-link">Back</button>
        </Link>
      </div>
      {loadingCountryStats && loadingStates ? (
        <Loader />
      ) : stateOverviewStats && states ? (
        <div className="flex flex-col md:flex-row w-screen h-full overflow-auto">
          <div className="w-full md:w-1/2 flex flex-col justify-start gap-y-5">
            <div className="w-full grid grid-cols-1 md:grid-cols-2">
              <div className="px-4 py-2 bg-gray-300 flex items-center justify-center gap-x-2">
                <span className="font-bold text-lg">Total Registrations:</span>
                <span className="font-bold text-2xl text-blue">
                  {stateOverviewStats.totalOnboardingCount}
                </span>
                <span className="font-semibold">(India)</span>
              </div>
              <div className="px-4 py-2 bg-gray-300 flex items-center justify-center gap-x-2">
                <span className="font-bold text-lg">Total Certifications:</span>
                <span className="font-bold text-2xl text-blue">
                  {stateOverviewStats.totalCourseCompletionCount}
                </span>
                <span className="font-semibold">(India)</span>
              </div>
            </div>
            <IndiaMap
              selectedStateId={statParams.stateId}
              onStateSelect={(stateId) =>
                setStatsParams("stateId")(Number(stateId))
              }
              stateOverviewStats={stateOverviewStats.result}
            />
          </div>

          {loadingStates ||
          loadingDistricts ||
          loadingStateOverviewStats ||
          loadingCountryStats ? (
            // <div className="w-1/2">
            <Loader />
          ) : // </div>
          states && districts && stateOverviewStats && countryStats ? (
            <Stats
              statParams={statParams}
              setStatsParams={setStatsParams}
              requiredData={{ states, districts }}
              statsData={{
                stateOverviewStats: stateOverviewStats.result,
                countryStats,
              }}
            />
          ) : (
            <div className="text-red font-semibold text-lg">
              An error occurred
            </div>
          )}
        </div>
      ) : fetchStatesErr ? (
        <div className="centered-page text-red-500 font-bold">
          Error fetching states data
        </div>
      ) : fetchCountryStatsErr ? (
        <div className="centered-page text-red-500 font-bold">
          Error fetching stats
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

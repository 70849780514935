import React, { useState } from "react";
import { Admin } from "@unicef/shared-resources/models/db";
import usePromise from "./usePromise";
import { getCookieObject } from "../cookie";
import { services } from "../../services";
import { setAccessToken } from "../../services/request";

export type AuthState = { is_authenticated: boolean } & Partial<{
  access_token: string;
  admin: Pick<Admin, "id" | "stateId" | "email" | "name">;
}>;

export function useAuth() {
  const [authState, setAuthState] = useState<AuthState>({
    is_authenticated: false,
  });

  const { error } = usePromise(
    {
      fn: async () => {
        const { access_token: accessToken } = getCookieObject();
        const cookieValidationResponse = await services.requestSvc
          .get<{
            id: number;
            email: string;
            name: string;
            stateId: number;
          }>("/validate-token", {
            headers: { Authorization: "Bearer " + accessToken },
          })
          .catch(console.error);

        console.log("Cookie validation response", cookieValidationResponse);

        if (cookieValidationResponse) {
          setAuthState({
            is_authenticated: true,
            access_token: accessToken,
            admin: {
              id: BigInt(cookieValidationResponse.id),
              email: cookieValidationResponse.email,
              stateId: cookieValidationResponse.stateId
                ? BigInt(cookieValidationResponse.stateId)
                : undefined,
              name: cookieValidationResponse.name,
            },
          });
          setAccessToken(accessToken);
        } else {
          setAuthState({
            is_authenticated: false,
            access_token: undefined,
            admin: undefined,
          });
          setAccessToken();
        }
      },
      runOnMount: { args: [] },
    },
    []
  );

  console.log(error);

  return authState;
}

export function objectToQueryString(obj: any, prefix: string = ""): string {
  const queryStringParts: string[] = [];

  for (const key in obj) {
    const value = obj[key];
    const fullKey = prefix ? `${prefix}[${key}]` : key;

    if (typeof value === "object" && value !== null) {
      // Handle nested objects or arrays recursively
      queryStringParts.push(objectToQueryString(value, fullKey));
    } else if (value !== undefined) {
      // Handle primitive values
      queryStringParts.push(
        `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`
      );
    }
  }

  return queryStringParts.join("&");
}

export function queryStringToObject(queryString: string): any {
  const obj: any = {};

  queryString.split("&").forEach((pair) => {
    const [rawKey, value] = pair.split("=");
    const decodedKey = decodeURIComponent(rawKey);
    const decodedValue = decodeURIComponent(value || "");

    const keys = decodedKey.split(/[\[\]]+/).filter((k) => k !== "");

    let current = obj;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (i === keys.length - 1) {
        // Set the value on the last key
        if (Array.isArray(current[key])) {
          current[key].push(decodedValue);
        } else if (current[key] !== undefined) {
          current[key] = [current[key], decodedValue];
        } else {
          current[key] = decodedValue;
        }
      } else {
        // Create nested object or array if necessary
        if (!current[key]) {
          current[key] = isNaN(parseInt(keys[i + 1], 10)) ? {} : [];
        }
        current = current[key];
      }
    }
  });

  return obj;
}

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Fonts
import "./assets/fonts/SegoeUIBoldItalic.ttf";
import "./assets/fonts/SegoeUIBold.ttf";
import "./assets/fonts/SegoeUIItalic.ttf";
import "./assets/fonts/SegoeUI.ttf";

import { PAGE_PATHS } from "./pages/page-paths";
import usePromise from "./utils/hooks/usePromise";
import { resolveServices } from "./services";
import VLEStatsPage from "./pages/VLEStats";
import HomePage from "./pages/Home";
import CountryStatsPage from "./pages/CountryStats";
import Loader from "./assets/animated-images/loader";
import UpdateUserCourseCompletionPage from "./pages/UpdateUserCourseCompletion";
import { useAuth } from "./utils/hooks/useAuth";
import LoginPage from "./pages/Login";

function App() {
  const { result: servicesResolved } = usePromise(
    { fn: resolveServices, runOnMount: { args: [] } },
    []
  );

  const { is_authenticated } = useAuth();

  if (servicesResolved) {
    return (
      <BrowserRouter>
        <Routes>
          {is_authenticated
            ? [
                <Route path={PAGE_PATHS.VLE_STATS} Component={VLEStatsPage} />,
                <Route
                  path={PAGE_PATHS.COUNTRY_STATS}
                  Component={CountryStatsPage}
                />,
                <Route
                  path={PAGE_PATHS.UPDATE_USER_COURSE_COMPLETION}
                  Component={UpdateUserCourseCompletionPage}
                />,
                <Route path={PAGE_PATHS.DEFAULT} Component={HomePage} />,
              ]
            : [<Route path={PAGE_PATHS.DEFAULT} Component={LoginPage} />]}
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </BrowserRouter>
    );
  } else
    return (
      <div className="centered-page">
        <Loader style={{ height: "40px" }} color="#999" />
      </div>
    );
}

export default App;

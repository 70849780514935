import React, { useMemo } from "react";

type StatDatePickerValue = { year: number; month: number };

type StatDatePickerProps = {
  value?: StatDatePickerValue;
  onChange?: (v: StatDatePickerValue) => any;
  minValue?: StatDatePickerValue;
  maxValue?: StatDatePickerValue;

  style?: any;
  className?: string;
};

const MONTH = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December",
};

const MIN_YEAR = 1990,
  MAX_YEAR = 2100;

export default function StatDatePicker({
  value: _value,
  onChange,
  minValue,
  maxValue,
  style = {},
  className = "",
}: StatDatePickerProps) {
  const value: StatDatePickerValue = useMemo(
    () =>
      _value || {
        month: maxValue?.month || new Date().getMonth() + 1,
        year: maxValue?.year || new Date().getFullYear(),
      },
    [_value, maxValue]
  );

  const handleChange =
    <F extends keyof StatDatePickerValue>(field: F) =>
    (newValue: StatDatePickerValue[F]) => {
      value[field] = newValue;
      if (typeof onChange === "function") onChange({ ...value });
    };

  const isYearHigherThanMax = useMemo(
      () => (maxValue && value.year > maxValue.year) || false,
      [value, maxValue]
    ),
    isYearLowerThanMin = useMemo(
      () => (minValue && value.year < minValue.year) || false,
      [value, minValue]
    ),
    isMonthHigherThanMax = useMemo(
      () =>
        (maxValue &&
          value.year * 100 + value.month >
            maxValue.year * 100 + maxValue.month) ||
        false,
      [value, maxValue]
    ),
    isMonthLowerThanMin = useMemo(
      () =>
        (minValue &&
          value.year * 100 + value.month <
            minValue.year * 100 + minValue.month) ||
        false,
      [value, minValue]
    );

  const isDateInvalid =
    isYearHigherThanMax ||
    isYearLowerThanMin ||
    isMonthHigherThanMax ||
    isMonthLowerThanMin;

  return (
    <div className={`flex flex-col gap-y-1 ${className}`} style={{ ...style }}>
      <div
        className={`grid grid-cols-2 gap-x-2 ${
          isDateInvalid ? "border-2 border-red-500" : ""
        }`}
      >
        <select
          onChange={(e) => handleChange("month")(Number(e.target.value))}
          className="input-primary"
          value={value.month}
        >
          {Object.keys(MONTH).map((monthNumber) => (
            <option
              value={monthNumber}
              disabled={
                (minValue &&
                  value.year * 100 + Number(monthNumber) <
                    minValue.year * 100 + minValue.month) ||
                (maxValue &&
                  value.year * 100 + Number(monthNumber) >
                    maxValue.year * 100 + maxValue.month)
              }
            >
              {MONTH[monthNumber as keyof typeof MONTH]}
            </option>
          ))}
        </select>

        <select
          onChange={(e) => handleChange("year")(Number(e.target.value))}
          className="input-primary"
          value={value.year}
        >
          {new Array(MAX_YEAR - MIN_YEAR)
            .fill("0")
            .map((_, n) => MIN_YEAR + n + 1)
            .map((yearNumber) => (
              <option
                value={yearNumber}
                disabled={
                  (minValue && yearNumber < minValue.year) ||
                  (maxValue && yearNumber > maxValue.year)
                }
              >
                {yearNumber}
              </option>
            ))}
        </select>
      </div>
      {isDateInvalid && <span className="text-red-500">Date is invalid</span>}
    </div>
  );
}

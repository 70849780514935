export const svgStateIdToBackendStateIdMap: { [svgStateId: string]: number } = {
  INAN: 1, // Andaman and Nicobar
  INTG: 32, // Telangana
  INAP: 2, // Andhra Pradesh
  INAR: 3, // Arunachal Pradesh
  INAS: 4, // Assam
  INBR: 5, // Bihar
  INCH: 6, // Chandigarh
  INCT: 7, // Chhattisgarh
  INDH: 8, // Dādra and Nagar Haveli and Damān and Diu
  INDL: 10, // Delhi
  INGA: 11, // Goa
  INGJ: 12, // Gujarat
  INHR: 13, // Haryana
  INHP: 14, // Himachal Pradesh
  INJH: 16, // Jharkhand
  INKA: 17, // Karnataka
  INKL: 18, // Kerala
  INMP: 20, // Madhya Pradesh
  INMH: 21, // Maharashtra
  INMN: 22, // Manipur
  INML: 23, // Meghalaya
  INMZ: 24, // Mizoram
  INNL: 25, // Nagaland
  INOR: 26, // Orissa
  INPY: 27, // Puducherry
  INPB: 28, // Punjab
  INRJ: 29, // Rajasthan
  INSK: 30, // Sikkim
  INTN: 31, // Tamil Nadu
  INTR: 33, // Tripura
  INUP: 34, // Uttar Pradesh
  INUT: 35, // Uttaranchal
  INWB: 36, // West Bengal
  INLD: 19, // Lakshadweep
  INJK: 15, // Jammu and Kashmir
  INLA: 37, // Ladakh
};

import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { serverName } from "../config";
import { useAuth } from "../utils/hooks/useAuth";

export default function HomePage() {
  const logout = useCallback(() => {
    document.cookie = "access_token=;domain=" + serverName;
    window.location.reload();
  }, []);

  const { admin } = useAuth();

  return (
    <div className="background-img w-screen h-screen centered-page">
      <div className="flex flex-col gap-y-3 p-8 rounded-xl bg-white">
        <h1 className="text-3xl font-bold mb-5">UNICEF Reports Dashboard</h1>
        <div className="mb-5 font-semibold">
          Welome! <span className="text-blue">{admin?.name}</span>
        </div>
        <Link to="/vle-stats">
          <button className="btn-primary w-full">VLE Stats</button>
        </Link>
        <Link to="/country-stats">
          <button className="btn-primary w-full">Country Stats</button>
        </Link>
        <button onClick={logout} className="btn-secondary">
          Logout
        </button>
      </div>
    </div>
  );
}

import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from "react";
import IndiaMapSvg from "./IndiaMapSvg";
import { svgStateIdToBackendStateIdMap } from "../../utils/svgStateIdToStateIdMap";
import {
  colorBelowHalfwayReached,
  colorLimitReached,
  colorOverHalfwayReached,
} from "../../config";
import { StateOverviewStat } from "../CountryStats";

export type IndiaMapProps = Partial<{
  selectedStateId: number;
  onStateSelect: (stateId: bigint) => any;
  onDeselect: (...args: any[]) => any;
  imgStyle: any;
  imgClassName: string;
  stateOverviewStats: StateOverviewStat[];
}>;

const getBackendIdFromSvgStateElementId = (stateElementId: string): bigint =>
  BigInt(svgStateIdToBackendStateIdMap[stateElementId] || -1);

const calculateCompletionRate = (stateStat?: StateOverviewStat): number => {
  if (!stateStat) return 0;

  const limit = stateStat.stateLimit || stateStat.onboardingCount;
  return limit > 0 ? ((stateStat.courseCompletionCount || 0) / limit) * 100 : 0;
};

const updateStateElementColor = (
  stateElement: SVGPathElement,
  completionRate: number,
  isSelected: boolean
) => {
  if (completionRate === 100) {
    stateElement.style.fill = colorLimitReached;
  } else if (completionRate >= 50) {
    stateElement.style.fill = colorOverHalfwayReached;
  } else {
    stateElement.style.fill = colorBelowHalfwayReached;
  }

  stateElement.style.opacity = isSelected ? "1" : "0.5";
};

const updateStateColors = (
  stateElements: SVGPathElement[],
  stateOverviewStats: StateOverviewStat[] | undefined,
  selectedStateId: number | undefined
) => {
  stateElements.forEach((stateElement) => {
    const backendId = getBackendIdFromSvgStateElementId(stateElement.id);
    const stateStat = stateOverviewStats?.find(
      (stat) => stat.stateId === Number(backendId)
    );
    const completionRate = calculateCompletionRate(stateStat);

    if (!selectedStateId || selectedStateId === -50) {
      updateStateElementColor(stateElement, completionRate, true);
    } else {
      const isSelected = backendId === BigInt(selectedStateId);
      updateStateElementColor(stateElement, completionRate, isSelected);
    }
  });
};

export default function IndiaMap(props: IndiaMapProps) {
  const {
    selectedStateId,
    onStateSelect = () => {},
    imgStyle,
    imgClassName,
    stateOverviewStats,
  } = props;

  console.log("countryStats", stateOverviewStats);

  const svgRef = useRef<SVGSVGElement>(null);

  const svgOnClick = useCallback<MouseEventHandler<SVGElement>>(
    (e) => {
      const target = e.target as SVGPathElement;
      if (target.id.startsWith("IN")) {
        onStateSelect(getBackendIdFromSvgStateElementId(target.id));
      }
    },
    [onStateSelect]
  );

  useEffect(() => {
    const statePathElements = Array.from(
      svgRef.current?.getElementsByTagName("path") || []
    );
    updateStateColors(statePathElements, stateOverviewStats, selectedStateId);
  }, [selectedStateId, stateOverviewStats]);

  return (
    <div className="w-full">
      <IndiaMapSvg
        ref={svgRef}
        style={imgStyle}
        className={`w-full ${imgClassName}`}
        onClick={svgOnClick}
      />
    </div>
  );
}

import React from "react";
import BaseLayout from "../layouts/base";
import { apiBaseUri } from "../config";

export default function LoginPage() {
  return (
    <div className="background-img w-screen h-screen centered-page">
      <div className="flex flex-col gap-y-3 p-8 rounded-xl bg-white">
        <h1 className="text-3xl font-bold mb-10">UNICEF P2E Admin Portal</h1>
        <form
          action={`${apiBaseUri}/admin-login`}
          method="post"
          className="flex flex-col gap-y-3 w-full max-w-[600px]"
        >
          <input
            type="text"
            name="email"
            placeholder="Email"
            className="input-primary"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="input-primary"
          />
          <input type="submit" className="btn-primary" value="Login" />
        </form>
      </div>
    </div>
  );
}

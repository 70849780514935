import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { objectToQueryString, queryStringToObject } from "../utils/query";
import usePromise from "../utils/hooks/usePromise";
import { services } from "../services";
import { District, State, VLE } from "@unicef/shared-resources/models/db";
import DataTable from "react-data-table-component";
import moment from "moment";
import { downloadFileFromURL } from "../utils/file";
import { useAuth } from "../utils/hooks/useAuth";

type VLEDataRecord = {
  id: bigint;
  name: string;
  email: string;
  cscId: string;
  stateId: bigint;
  createdAt: string;
  updatedAt: string;
  districtId: bigint;
  userOnboardingCount: number;
  userConfirmedOnboardingCount: number;
  userCourseCompletionCount: number;
  stateName: string;
  districtName: string;
  districtCode: string;
};

export default function VLEStatsPage() {
  const navigate = useNavigate();
  const { admin } = useAuth();

  /*




  Parsing and setting stats parameters




  */
  const searchQuery: {
    params: { vleCscId?: string; stateId?: number; districtId?: number };
    paginationParams: {
      pageIndex: number;
      pageSize: number;
      orderBy: { fieldName: keyof VLE; dir: "asc" | "desc" }[];
    };
  } = useMemo(() => {
    return {
      params: {},
      paginationParams: {
        pageSize: 10,
        pageIndex: 0,
        orderBy: [],
      },
      ...queryStringToObject(window.location.search.slice(1)),
    };
  }, [window.location.search]);

  const setSearchQuery = useCallback(
    (newSearchQuery: typeof searchQuery) => {
      const queryString = objectToQueryString(newSearchQuery);
      if (queryString !== window.location.search.slice(1)) {
        navigate({ search: queryString });
      } else runSearch();
    },
    [navigate]
  );

  /*




  Fetching states and district data




  */
  const {
    result: states,
    error: fetchStatesErr,
    loading: loadingStates,
  } = usePromise(
    {
      fn: () =>
        services.requestSvc.get<State[]>("/states").then((e) => {
          if (admin?.stateId)
            return e.filter((e) => BigInt(e.id) === admin.stateId);
          return e;
        }),
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [admin?.stateId]
  );

  const {
    result: districts,
    error: fetchDistrictsErr,
    loading: loadingDistricts,
  } = usePromise(
    {
      fn: async () => {
        if (searchQuery.params.stateId) {
          return services.requestSvc.get<District[]>(
            "/districts-by-state-id?stateId=" + searchQuery.params.stateId
          );
        } else return [];
      },
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [searchQuery.params.stateId]
  );

  /*




  Fetching records




  */
  const {
    run: runSearch,
    result: vleList,
    loading: fetchingVLEList,
    error: fetchVLEError,
  } = usePromise(
    {
      fn: async () =>
        services.requestSvc.post<{
          data: VLEDataRecord[];
          count: number;
        }>("/get-vles-by-params-paginated", searchQuery),
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [searchQuery]
  );

  /*




  Download Reports




  */
  const {
    run: downloadCSV,
    loading: downloadingCsvReport,
    error: downloadCSVError,
  } = usePromise(
    {
      fn: async () => {
        const { csvReportDownloadLink, csvReportFilename } =
          await services.requestSvc.post<{
            csvReportDownloadLink: string;
            csvReportFilename: string;
          }>("/download-vle-report", searchQuery.params);

        downloadFileFromURL(csvReportDownloadLink, csvReportFilename);
      },
    },
    [searchQuery]
  );

  /*




  Misc




  */
  const [transientCscId, setTransientCscId] = useState("");

  const setSearchParams = useCallback(
    (field: keyof typeof searchQuery.params) =>
      (value: (typeof searchQuery.params)[typeof field]) => {
        Object.assign(searchQuery.params, { [field]: value });

        const district = districts?.find(
          (d) => Number(d.id) === Number(searchQuery.params.districtId)
        );

        if (
          !district ||
          Number(district.stateId) !== Number(searchQuery.params.stateId)
        ) {
          searchQuery.params.districtId = undefined;
        }

        navigate({ search: objectToQueryString(searchQuery.params) });
        setSearchQuery({ ...searchQuery });
      },
    [searchQuery, setSearchQuery, districts, navigate]
  );

  useEffect(() => {
    if (states?.length === 1) {
      setSearchParams("stateId")(Number(states[0].id));
    }
  }, [states, searchQuery, setSearchParams]);

  return (
    <div className="flex flex-col w-screen h-screen">
      <div className="py-2 px-4 bg-white text-left border-b border-gray-400">
        <Link to="/">
          <button className="btn-link">Back</button>
        </Link>
      </div>
      <div className="flex flex-col w-screen max-w-[1600px] mx-auto items-start pt-5 px-4">
        <div className="text-2xl font-semibold mb-5">VLE Reports</div>
        <div className="flex flex-col justify-center gap-y-2">
          <div className="flex flex-col lg:flex-row gap-x-2 gap-y-2">
            <input
              type="text"
              className="input-primary w-full lg:w-1/2"
              onChange={(e) => setTransientCscId(e.target.value)}
              onKeyDownCapture={(e) => {
                if (e.key === "Enter")
                  setSearchParams("vleCscId")(transientCscId);
              }}
              placeholder="Enter VLE's CSC ID"
              value={transientCscId}
            />
            <div className="flex w-full gap-x-2 lg:w-1/2">
              <select
                onChange={(e) =>
                  setSearchParams("stateId")(e.target.value || undefined)
                }
                className="input-primary w-1/2"
                value={searchQuery.params.stateId}
              >
                <option value="">All States</option>
                {states?.map((s) => (
                  <option value={Number(s.id)} key={s.id}>
                    {s.name}
                  </option>
                ))}
              </select>

              <select
                onChange={(e) =>
                  setSearchParams("districtId")(e.target.value || undefined)
                }
                className="input-primary w-1/2"
                value={searchQuery.params.districtId}
              >
                <option value="">All Districts</option>
                {districts?.map((d) => (
                  <option value={Number(d.id)} key={d.id}>
                    {d.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            className="btn-primary"
            onClick={() => {
              setSearchParams("vleCscId")(transientCscId);
            }}
          >
            Search
          </button>
          <button className="btn-secondary" onClick={downloadCSV}>
            Download CSV Report
          </button>
        </div>
        <DataTable<VLEDataRecord>
          columns={[
            {
              name: "VLE ID",
              selector: (row) => row.cscId,
              width: "18rem",
            },
            {
              name: "VLE Name",
              selector: (row) => row.name,
            },
            {
              name: "State",
              selector: (row) => row.stateName,
            },
            {
              name: "District",
              selector: (row) => row.districtName || "Unknown",
            },
            {
              name: "Onboarding Count",
              selector: (row) => row.userOnboardingCount,
              width: "19rem",
            },
            {
              name: "Course Completion Count",
              selector: (row) => row.userCourseCompletionCount,
            },
            {
              name: "Activated On",
              selector: (row) =>
                moment(row.createdAt).format("Y MMM DD, HH:mm:SS"),
            },
          ]}
          data={vleList?.data || []}
          pagination
          paginationServer
          paginationPerPage={searchQuery.paginationParams.pageSize}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          paginationTotalRows={vleList?.count}
          onChangePage={(pageNumber) => {
            setSearchQuery({
              ...searchQuery,
              paginationParams: {
                ...searchQuery.paginationParams,
                pageIndex: pageNumber - 1,
              },
            });
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setSearchQuery({
              ...searchQuery,
              paginationParams: {
                ...searchQuery.paginationParams,
                pageIndex: 0,
                pageSize: currentRowsPerPage,
              },
            });
          }}
          className="w-full mt-10"
        />
      </div>
    </div>
  );
}

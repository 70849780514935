import React, { useMemo } from "react";
import {
  CountryStats,
  StateOverviewStat,
  StatsParams,
  StatsParamsSetter,
} from "../CountryStats";
import { District, State } from "@unicef/shared-resources/models/db";
import StatDatePicker from "./StatDatePicker";
import _ from "lodash";

type StatsProps = {
  statParams: StatsParams;
  setStatsParams: StatsParamsSetter;
  requiredData: {
    states: State[];
    districts: District[];
  };
  statsData: {
    stateOverviewStats: StateOverviewStat[];
    countryStats: CountryStats[];
  };
};

type GenderBreakdown = {
  male: number;
  female: number;
  other: number;
};

type FormattedStats = {
  totalOnboardingCount: {
    total: number;
    genderBreakdown: GenderBreakdown;
  };
  confirmedOnboardingCount: {
    total: number;
    genderBreakdown: GenderBreakdown;
  };
  courseCompletionCount: {
    total: number;
    genderBreakdown: GenderBreakdown;
  };
};

export default function Stats({
  statParams,
  setStatsParams,
  requiredData: { states, districts },
  statsData: { stateOverviewStats, countryStats },
}: StatsProps) {
  const currentState = useMemo(() => {
    if (states && statParams.stateId && stateOverviewStats) {
      const state = states.find((s) => Number(s.id) === statParams.stateId);
      const stateStat = stateOverviewStats.find(
        (e) => e.stateId === statParams.stateId
      );
      return { ...state, stats: stateStat };
    }
  }, [states, stateOverviewStats, statParams.stateId]);

  const allStats = useMemo<FormattedStats>(
    () => getFormattedCountryStats(countryStats),
    []
  );

  return (
    <div className="w-full md:w-1/2 bg-gray-100">
      <div className="w-full min-h-full flex flex-col justify-center p-5 gap-y-5 max-w-[700px] mx-auto">
        <span className="text-3xl font-semibold mb-5">Onboarding Report</span>
        {/*
      
      


      Filters


      
      
      */}
        <div className="grid grid-cols-2 gap-x-4 w-full mb-5">
          <select
            className="font-bold text-xl rounded-lg px-4 py-2 border-2 border-gray-300"
            value={statParams.stateId}
            onChange={(e) => setStatsParams("stateId")(Number(e.target.value))}
          >
            <option value={""}>All states</option>
            {states.map((s) => (
              <option key={s.id} value={Number(s.id)}>
                {s.name}
              </option>
            ))}
          </select>
          {currentState && (
            <select
              className="font-bold text-xl rounded-lg px-4 py-2 border-2 border-gray-300"
              value={statParams.districtId}
              onChange={(e) =>
                setStatsParams("districtId")(Number(e.target.value))
              }
            >
              <option value={""}>All districts</option>
              {districts.map((d) => (
                <option key={d.id} value={Number(d.id)}>
                  {d.name}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="grid grid-cols-2 gap-x-4 w-full mb-5">
          <div className="flex items-center gap-x-2">
            <span className="font-semibold">From</span>
            <StatDatePicker
              value={statParams?.dateRange?.from}
              onChange={(v) => {
                _(statParams).set("dateRange.from", v);
                setStatsParams("dateRange")({ ...statParams.dateRange });
              }}
            />
          </div>
          <div className="flex items-center gap-x-2">
            <span className="font-semibold">To</span>
            <StatDatePicker
              value={statParams?.dateRange?.to}
              onChange={(v) => {
                _(statParams).set("dateRange.to", v);
                setStatsParams("dateRange")({ ...statParams.dateRange });
              }}
              minValue={statParams?.dateRange?.from}
            />
          </div>
        </div>

        {/*
      
      


      Stats


      
      
      */}

        {currentState && (
          <div className="mb-5 text-lg flex items-center gap-x-2">
            <span className="font-semibold">State Limit:</span>{" "}
            <span className="font-semibold text-blue text-2xl">
              {currentState.stats?.stateLimit}
            </span>
          </div>
        )}

        {[
          "totalOnboardingCount" as const,
          "courseCompletionCount" as const,
        ].map((statGroup) => (
          <div className="w-full rounded-xl bg-white overflow-hidden">
            <div className="w-full bg-slate-300 py-2 px-4 font-semibold">
              {statGroup === "totalOnboardingCount"
                ? "Onboarding Count (on Pre Registration portal)"
                : "Course Completion Count"}
            </div>
            <div className="w-full grid grid-cols-2 md:grid-cols-4">
              <div className="w-full flex flex-col items-center justify-center py-4 gap-y-2">
                <span className="text-3xl text-blue font-semibold">
                  {allStats[statGroup].total}
                </span>
                <span className="font-semibold">Total</span>
              </div>
              <div className="w-full flex flex-col items-center justify-center py-4 gap-y-2">
                <span className="text-3xl text-blue font-semibold">
                  {allStats[statGroup].genderBreakdown.male}
                </span>
                <span className="font-semibold">Male</span>
              </div>
              <div className="w-full flex flex-col items-center justify-center py-4 gap-y-2">
                <span className="text-3xl text-blue font-semibold">
                  {allStats[statGroup].genderBreakdown.female}
                </span>
                <span className="font-semibold">Female</span>
              </div>
              <div className="w-full flex flex-col items-center justify-center py-4 gap-y-2">
                <span className="text-3xl text-blue font-semibold">
                  {allStats[statGroup].genderBreakdown.other}
                </span>
                <span className="font-semibold">Other</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const getFormattedCountryStats = (countryStats: CountryStats[]) => ({
  totalOnboardingCount: {
    total: countryStats.reduce((acc, cs) => {
      acc += cs.onboardingCount;
      return acc;
    }, 0),
    genderBreakdown: {
      male: countryStats
        .filter((e) => e.gender === "male")
        .reduce((acc, cs) => {
          acc += cs.onboardingCount;
          return acc;
        }, 0),
      female: countryStats
        .filter((e) => e.gender === "female")
        .reduce((acc, cs) => {
          acc += cs.onboardingCount;
          return acc;
        }, 0),
      other: countryStats
        .filter((e) => e.gender === "other")
        .reduce((acc, cs) => {
          acc += cs.onboardingCount;
          return acc;
        }, 0),
    },
  },
  confirmedOnboardingCount: {
    total: countryStats
      .filter((e) => e.onboardingStatus === "confirmed")
      .reduce((acc, cs) => {
        acc += cs.onboardingCount;
        return acc;
      }, 0),
    genderBreakdown: {
      male: countryStats
        .filter((e) => e.onboardingStatus === "confirmed")
        .filter((e) => e.gender === "male")
        .reduce((acc, cs) => {
          acc += cs.onboardingCount;
          return acc;
        }, 0),
      female: countryStats
        .filter((e) => e.onboardingStatus === "confirmed")
        .filter((e) => e.gender === "female")
        .reduce((acc, cs) => {
          acc += cs.onboardingCount;
          return acc;
        }, 0),
      other: countryStats
        .filter((e) => e.onboardingStatus === "confirmed")
        .filter((e) => e.gender === "other")
        .reduce((acc, cs) => {
          acc += cs.onboardingCount;
          return acc;
        }, 0),
    },
  },
  courseCompletionCount: {
    total: countryStats.reduce((acc, cs) => {
      acc += cs.courseCompletionCount;
      return acc;
    }, 0),
    genderBreakdown: {
      male: countryStats
        .filter((e) => e.gender === "male")
        .reduce((acc, cs) => {
          acc += cs.courseCompletionCount;
          return acc;
        }, 0),
      female: countryStats
        .filter((e) => e.gender === "female")
        .reduce((acc, cs) => {
          acc += cs.courseCompletionCount;
          return acc;
        }, 0),
      other: countryStats
        .filter((e) => e.gender === "other")
        .reduce((acc, cs) => {
          acc += cs.courseCompletionCount;
          return acc;
        }, 0),
    },
  },
});
